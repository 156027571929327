import * as React from "react"
import styled from 'styled-components';
import { useMediaQuery } from "../util/hook";

// styles
const headingStyles = {
  marginTop: 64,
  marginBottom: 4,
  maxWidth: 320,
  color: "#000"
}

const AlternateMain = styled.div`
  color: #232129;
  font-family: -apple-system, Roboto, sans-serif, serif;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

// const headingAccentStyles = {
//   color: "#8bc34a",
//   display: "none",
// }

const cardStyle = {
  color: "#fff",
  padding: 8,
  backgroundColor: "#0b0b0b",
  fontSize: "1.25rem",
  borderRadius: 6,
  textAlign: "center",
}

const cardsStyle = {
  container: isRowBased => ({
    display: "grid",
    gridColumnGap: "2rem",
    gridRowGap: "2rem",
    gridTemplateColumns: isRowBased ? 'auto auto auto' : 'auto',
    width: "100%",
    maxWidth: "80%",
  })
};

const logo = {
  display: "none",
}

const purpose = {
  lineHeight: "1.9rem",
  fontWeight: 500,
  margin: "1.2rem 0 0",
  fontSize: "1.5rem",
  color: "black",
  marginBottom: "2rem",
  textAlign: "center",
}

// data
const links = [
  {
    text: "Branding",
    url: "#",
    // description:
    //   "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "Client Success",
    url: "#",
    // description:
    //   "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Intelligent Automation",
    url: "#",
    // description:
    //   "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Consultancy",
    url: "#",
    // description:
    //   "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
]


// markup
const IndexPage = () => {
  const isRowBased = useMediaQuery('(min-width: 500px)');
  return (
    // <main style={pageStyles}>
    <AlternateMain>
      <title>Hello!</title>
      <h1 style={headingStyles}>
        Udeme.dev
        {/* <br />
        <span style={headingAccentStyles}>— you just made a Gatsby site! </span>
        <span role="img" aria-label="Party popper emojis">
          🎉🎉🎉
        </span> */}
      </h1>

      <p style={purpose}>
        Passionate about solving business problems with a pragmatic approach.
      </p>

      <cards style={cardsStyle.container(isRowBased)}>
        {links.map(link => (
          <card key={link} style={cardStyle}>
            <span>{link.text}</span>
          </card>
        ))}
      </cards>
      <img style={logo}
        alt="Gatsby G Logo"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='42' height='42' viewBox='0 0 512 512'%3E%3Cdefs%3E%3CclipPath id='b'%3E%3Crect width='512' height='512'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='a' clip-path='url(%23b)'%3E%3Ctext transform='translate(58 464)' fill='%238bc34a' font-size='561' font-family='SFProDisplay-Bold, SF Pro Display' font-weight='700'%3E%3Ctspan x='0' y='0'%3EU%3C/tspan%3E%3C/text%3E%3Cg transform='translate(198.17 620.737)'%3E%3Cpath d='M0,33.713,33.713,0A23.867,23.867,0,1,1,0,33.713Z' transform='translate(233.321 -203.246)' fill='%238bc34a'/%3E%3Cpath d='M0,33.713,33.713,0A23.867,23.867,0,1,1,0,33.713Z' transform='translate(233.321 -203.246)' fill='%238bc34a'/%3E%3Cpath d='M0,33.713,33.713,0A23.867,23.867,0,1,1,0,33.713Z' transform='translate(233.321 -203.246)' fill='%238bc34a'/%3E%3Cpath d='M0,33.713,33.713,0A23.867,23.867,0,1,1,0,33.713Z' transform='translate(268.509 -173.491) rotate(180)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
      />
    </AlternateMain>
  )
}

export default IndexPage
